// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { toast } from "react-toastify";

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
} = process.env;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

export const auth = getAuth();

export const socialLogout = async () => {
  try {
    const user = await auth.currentUser;
    if (user) {
      await auth.signOut();
    }
  } catch (error) {
    console.error("firebase error", error);
  }
};

export const sentPhoneOtp = async (phoneNumber) => {
  try {
    const recaptchaVerifire = document.getElementById("recaptcha-container");
    window.appVerifier = new RecaptchaVerifier(auth, recaptchaVerifire, {
      size: "invisible",
    });
    const appVerifier = window.appVerifier;
    const confirmation = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      appVerifier
    );
    window.confirmation = confirmation;
  } catch (error) {
    console.error("phone otp error", error);
  }
};

export const resendPhoneOtp = async (phoneNumber) => {
  try {
    const recaptchaVerifire = document.getElementById(
      "recaptcha-container-resend"
    );
    window.appVerifier = new RecaptchaVerifier(auth, recaptchaVerifire, {
      size: "invisible",
    });
    const appVerifier = window.appVerifier;
    const confirmation = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      appVerifier
    );
    window.confirmation = confirmation;
  } catch (error) {
    console.error("phone otp error", error);
  }
};

export const handleOtpVerify = async (otp) => {
  try {
    const confirmation = window.confirmation;
    const response = await confirmation.confirm(otp);

    return {
      res: response,
      verified: true,
    };
  } catch (error) {
    console.error("Error verifying OTP:", error);
    toast.error("You have entered Invalid OTP. Please try again.");
    return {
      verified: false,
      errorMessage: error.message,
    };
  }
};

export const getFirebaseUser = async () => {
  try {
    const user = await auth.currentUser;
    return user.phoneNumber;
  } catch (error) {
    console.error("error");
  }
};
